<template>
  <div v-loading="loading">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1 class="title">
        Active Alerts
      </h1>
      <el-button
        type="primary"
        @click="openAlertForm(undefined)"
      >
        New Alert
      </el-button>
    </el-row>
    <data-table :data="alerts">
      <el-table-column
        label="Company Name"
        prop="company.human_name"
        sortable
        width="300"
      >
        <template slot-scope="scope">
          {{ (scope.row.company && scope.row.company.human_name) || 'ALL COMPANIES' }}
        </template>
      </el-table-column>
      <el-table-column
        label="Message"
        prop="message"
        class-name="pre"
      />
      <el-table-column width="80" align="center">
        <template slot-scope="scope">
          <div>
            <el-button type="text" @click="openAlertForm(scope.row)">
              Edit
            </el-button>
          </div>
          <div>
            <el-button type="text" @click="openDeleteDialog(scope.row)">
              Delete
            </el-button>
          </div>
        </template>
      </el-table-column>
    </data-table>
    <alert-form
      :show="showAlertForm"
      :alert="selectedAlert"
      :alerts="alerts"
      @close="showAlertForm = false"
      @submit="updateAlert"
    />
    <alert-delete-dialog
      :show="showDeleteDialog"
      :alert="selectedAlert"
      @close="showDeleteDialog = false"
      @submit="deleteAlert"
    />
  </div>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import DataTable from '@/components/tables/DataTable'
import AlertForm from './AlertForm'
import AlertDeleteDialog from './AlertDeleteDialog'

export default {
  name: 'AlertsList',
  components: {
    'data-table': DataTable,
    'alert-form': AlertForm,
    'alert-delete-dialog': AlertDeleteDialog
  },
  data () {
    return {
      loading: false,
      alerts: [],
      selectedAlert: undefined,
      showAlertForm: false,
      showDeleteDialog: false
    }
  },
  created () {
    this.fetchAnnouncements()
  },
  methods: {
    /**
     * Fetches list of announcements from the API
     */
    async fetchAnnouncements () {
      try {
        this.loading = true
        const response = await RooofAPI.announcements.list()
        this.alerts = response.results
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Opens the create/edit dialog
     *
     * @param {Object} alert
     */
    openAlertForm (alert) {
      this.selectedAlert = alert
      this.showAlertForm = true
    },
    /**
     * Calls the API to create/update an alert.
     * Because of the 1:1 relationship between announcement and company,
     * we can use the same endpoint for creating and updating.
     *
     * @param {Object} alert
     */
    async updateAlert (alert) {
      this.showAlertForm = false
      try {
        this.loading = true
        const id = alert.company ? alert.company.id : 'global'
        await RooofAPI.announcements.partialUpdate(id, alert)
        this.fetchAnnouncements()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
        this.loading = false
      }
    },
    /**
     * Opens the delete confirmation dialog
     *
     * @param {Object} alert
     */
    openDeleteDialog (alert) {
      this.selectedAlert = alert
      this.showDeleteDialog = true
    },
    /**
     * Calls the API to delete the selected alert.
     */
    async deleteAlert () {
      try {
        this.loading = true
        const id = this.selectedAlert.company ? this.selectedAlert.company.id : 'global'
        await RooofAPI.announcements.delete(id)
        this.fetchAnnouncements()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
        this.showDeleteDialog = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .pre .cell {
  white-space: pre;
}
.title {
  font-size: 2rem;
  margin: 0 0 0.5em;
}
</style>
