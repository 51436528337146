<template>
  <el-dialog
    :visible="show"
    :show-close="false"
    :title="isEdit ? 'Edit Alert' : 'New Alert'"
  >
    <el-row class="company-radio">
      <el-radio-group
        v-model="global"
        :disabled="isEdit"
        @change="$refs['company-select'].clearValidate()"
      >
        <el-radio :label="true">
          Alert all companies
        </el-radio>
        <el-radio :label="false">
          Alert a single company
        </el-radio>
      </el-radio-group>
    </el-row>

    <el-form
      ref="alert-form"
      :model="formData"
      :rules="rules"
      label-width="140px"
      label-position="left"
    >
      <el-form-item
        ref="company-select"
        label="Company Name"
        prop="company"
      >
        <el-select
          v-model="formData.company"
          :placeholder="global ? 'All Companies' : 'Company'"
          :disabled="global || isEdit"
          value-key="id"
          filterable
        >
          <el-option
            v-for="company in companies"
            :key="company.id"
            :label="company.human_name"
            :value="company"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Message"
        prop="message"
      >
        <el-input
          v-model="formData.message"
          type="textarea"
          :maxlength="1000"
          show-word-limit
          :autosize="{ minRows: 5 }"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="submit"
      >
        {{ isEdit ? 'Save' : 'Create Alert' }}
      </el-button>
    </span>

    <alert-overwrite-dialog
      :show="showOverwriteWarning"
      :alert="alertToOverwrite"
      @close="showOverwriteWarning = false"
      @submit="overwriteHandler"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import AlertOverwriteDialog from './AlertOverwriteDialog'

export default {
  name: 'AlertForm',
  components: {
    'alert-overwrite-dialog': AlertOverwriteDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    alert: {
      type: Object,
      default: null
    },
    alerts: {
      type: Array,
      required: true
    }
  },
  data () {
    const companyValidator = (rule, value, callback) => {
      if (!this.global && !value) {
        callback(new Error('Please select a company'))
      } else {
        callback()
      }
    }
    return {
      formData: null,
      global: true,
      rules: {
        company: [
          { validator: companyValidator, trigger: 'change' }
        ],
        message: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ]
      },
      showOverwriteWarning: false
    }
  },
  computed: {
    isEdit () {
      return !!this.alert
    },
    ...mapGetters({
      companies: 'companyList'
    }),
    alertToOverwrite () {
      if (!this.isEdit) {
        return this.formData.company
          ? this.alerts.find(alert => (alert.company && alert.company.id) === this.formData.company.id)
          : this.alerts.find(alert => alert.company === null)
      }
      return undefined
    }
  },
  watch: {
    alert: {
      handler () {
        this.formData = this.getAlertObject()
      },
      immediate: true
    },
    global (value) {
      if (value) {
        this.formData.company = null
      }
    }
  },
  methods: {
    /**
     * Closes the dialog and resets the form
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Initializes the form data.
     * If editing an existing alert, set the form data accordingly.
     */
    getAlertObject () {
      if (this.isEdit) {
        this.global = !this.alert.company
        return JSON.parse(JSON.stringify(this.alert))
      }

      this.global = true
      return {
        company: null,
        message: '',
        enabled: true
      }
    },
    /**
     * Validates the form, opens the overwrite warning if necessary,
     * sends the formdata to the parent component for submission,
     * and resets the form.
     */
    submit () {
      this.$refs['alert-form'].validate(valid => {
        if (!valid) {
          return
        }
        if (this.alertToOverwrite) {
          this.showOverwriteWarning = true
          return
        }
        this.$emit('submit', this.formData)
        this.reset()
      })
    },
    /**
     * Clears form validation and resets the form data.
     */
    reset () {
      this.$refs['alert-form'].clearValidate()
      this.formData = this.getAlertObject()
    },
    /**
     * Handler for when the user confirms that it is ok
     * to overwrite the existing alert.
     */
    overwriteHandler () {
      this.showOverwriteWarning = false
      this.$emit('submit', this.formData)
      this.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.company-radio {
  margin-bottom: 1.5em;
}
</style>
