<template>
  <el-dialog
    :visible="show"
    :show-close="false"
    title="Overwrite Alert?"
    append-to-body
    class="alert-overwrite-dialog"
  >
    <p>
      <b>{{ (alert.company && alert.company.human_name) || 'All Companies' }}</b>
      has the following active alert.
      Do you want to overwrite this alert?
    </p>
    <div class="overwrite-message">
      {{ alert.message }}
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">
        &lt; Back
      </el-button>
      <el-button
        type="danger"
        @click="$emit('submit')"
      >
        Overwrite
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'AlertOverwriteDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    alert: {
      type: Object,
      default: () => ({
        company: null,
        message: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-overwrite-dialog {
  ::v-deep .el-dialog__header {
    padding-bottom: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
  .overwrite-message {
    padding: 1em;
    white-space: pre;
    background-color: #f5f7fa; // $--background-color-base
  }
}
</style>
